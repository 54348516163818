<template>
  <div class="hotel-box" @click="tapOrder">
    <div class="flex-between-center top">
      <div class="flex-start-center">
        <img src="@/assets/images/calendarRoomHotel/hotel.png" class="icon">
        <p class="font-32 bold">{{order.hotelName}}</p>
      </div>
      <p>{{ HOTEL_ORDER_STATUS_TEXT[order.status] }}</p>
    </div>
    <div class="flex-between-center p-t-18">
      <div class="supColor">
        <p class="m-b-10">{{order.roomsCount}}间，{{order.hotelRoomName}}</p>
        <p class="m-b-10">{{order.checkInDate.substring(0,10)}} ~ {{order.checkOutDate.substring(0,10)}}</p>
        <p>{{order.contactsName}}</p>
      </div>
      <div class="price">¥{{order.paymentAmount}}</div>
    </div>
  </div>
</template>

<script>
import { HOTEL_ORDER_STATUS_TEXT } from '@/constants/order.js'
export default {
  props:['order'],
  data() {
    return {
      HOTEL_ORDER_STATUS_TEXT
    }
  },
  methods:{
    tapOrder(){
      this.$emit('tapOrder',this.order)
    }
  }
};
</script>

<style lang="scss" scoped>
.hotel-box {
  padding: 32px 24px;
  background-color: #ffffff;
  border-radius: 10px;
  .top {
    padding-bottom: 22px;
    border-bottom: 1px solid #e8e8e8;
    .icon {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }
  }
  .price {
    font-size: 36px;
    color: #ff5733;
  }
}
</style>