import request from '@/tool/axios'
// 订单列表
export function orderList(data) {
    return request({
        url: '/order/hotelorder/search',
        data
    })
}

// 订单详情
export function orderDetail(params) {
    return request({
        url: '/order/hotelorder/detailbytenant',
        method:'get',
        params,
    })
}

// 拒绝订单
export function refuse(params) {
    return request({
        url: '/order/hotelorder/refuse',
        params
    })
}

// 确认订单
export function confirm(params) {
    return request({
        url: '/order/hotelorder/confirm',
        params
    })
}

// 入住
export function liveIn(params) {
    return request({
        url: '/order/hotelorder/checkin',
        params
    })
}

// 离店
export function leave(params) {
    return request({
        url: '/order/hotelorder/checkout',
        params
    })
}