<template>
  <div>
    <van-popup v-model="show" round closeable position="bottom">
      <div class="box">
        <p class="room">{{detail.hotelName}}</p>
        <p class="state">{{detail.statusString}}</p>
        <p class="title">订单信息</p>
        <div class="flex-start-center content">
          <p class="left">订单号</p>
          <p class="font-30">{{detail.baseOrderId}}</p>
        </div>
        <div class="flex-start-center content">
          <p class="left">售卖平台</p>
          <p class="font-30">微商城公众号</p>
        </div>
        <div class="flex-start-center content">
          <p class="left">下单时间</p>
          <p class="font-30">{{detail.createTime}}</p>
        </div>
        <div class="flex-start-center">
          <p class="title">订单详情</p>
          <p class="copy" :data-clipboard-text="copyText" @click="copy">复制</p>
        </div>
        <div class="flex-start-center content">
          <p class="left">房型</p>
          <p class="font-30">{{detail.hotelRoomName}}</p>
        </div>
        <div class="flex-start-center content">
          <p class="left">含早</p>
          <p class="font-30">{{detail.priceStrategyNumberOfBreakfast}}份早餐</p>
        </div>
        <div class="flex-start-center content">
          <p class="left">入离时间</p>
          <p class="font-30">{{detail.checkInDate}}~{{detail.checkOutDate}}({{detail.priceStrategyRoomsCount}}间{{detail.priceStrategyNightsCount}}晚)</p>
        </div>
        <div class="flex-start-center content">
          <p class="left">入住人</p>
          <p class="font-30">{{detail.contactsName}}</p>
        </div>
        <div class="flex-start-center content">
          <p class="left">联系电话</p>
          <p class="font-30">{{detail.contactsPhoneNumber}}</p>
        </div>
        <div class="flex-center-center">
          <div v-if="detail.liveState == 2" class="cancel" @click="refuseOrder">拒绝订单</div>
          <div v-if="detail.liveState == 2" class="confirm" @click="confirmOrder">确认订单</div>
          <div v-if="showLive" class="confirm" style="margin-left:0" @click="liveInOrder">已入住</div>
          <div v-if="showLeave" class="confirm" style="margin-left:0" @click="leaveOrder">已退房</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { orderDetail, refuse, confirm, liveIn, leave } from "@/api/order.js";
import { HOTEL_ORDER_STATUS_TEXT } from '@/constants/order.js'
export default {
  data() {
    return {
      show: false,
      detail: {},
    };
  },
  computed: {
    // 是否可以显示已入住
    showLive() {
      // 只有酒店为人工确认订单状态&&待入住状态下&& 入住日期<=今天  有已入住按钮
      return (
        !this.detail.hotelIsAutoConfirmRoomStatus && this.detail.liveState == 3 &&  Date.parse(this.detail.checkInDate) <= Date.parse(new Date())
      );
    },
    // 是否可以显示已离店
    showLeave() {
      // 只有酒店为人工确认订单状态&&已入住状态下 && 离店日期>=今天 有已退房按钮
      return (
        !this.detail.hotelIsAutoConfirmRoomStatus && this.detail.liveState == 4 && Date.parse(this.detail.checkOutDate) <= Date.parse(new Date())
      );
    },
    // 复制内容
    copyText() {
      return `酒店名称：${this.detail.hotelName}
房型名称：${this.detail.hotelRoomName}
含早：${this.detail.priceStrategyNumberOfBreakfast}份早餐
入离时间：${this.detail.checkInDate}~${this.detail.checkOutDate}(${this.detail.priceStrategyRoomsCount}间${this.detail.priceStrategyNightsCount}晚)
入住人：${this.detail.contactsName}
联系电话：${this.detail.contactsPhoneNumber}`;
    },
  },
  methods: {
    // 打开详情弹窗
    async open(baseOrderId) {
      await this.getDetail(baseOrderId);
      this.show = true;
    },
    // 关闭详情弹窗
    close() {
      this.show = false;
    },
    // 获取详情
    async getDetail(baseOrderId) {
      const res = await orderDetail({ baseOrderId });
      res.baseOrderId = baseOrderId;
      res.statusString = HOTEL_ORDER_STATUS_TEXT[res.orderDetail.status]
      res.hotelName = res.orderDetail.hotelName;
      res.hotelRoomName = res.orderDetail.hotelRoomName;
      res.checkInDate = res.orderDetail.checkInDate.substring(0, 10);
      res.checkOutDate = res.orderDetail.checkOutDate.substring(0, 10);
      res.priceStrategyRoomsCount = res.orderDetail.priceStrategyRoomsCount;
      res.priceStrategyNightsCount = res.orderDetail.priceStrategyNightsCount;
      res.priceStrategyIsAutoConfirm =
        res.orderDetail.priceStrategyIsAutoConfirm;
      res.priceStrategyNumberOfBreakfast =
        res.orderDetail.priceStrategyNumberOfBreakfast;
      res.liveState = res.orderDetail.status;
      this.detail = res;
      console.log(this.detail);
    },
    // 复制
    copy() {
      let clipboard = new Clipboard(".copy");
      clipboard.on("success", (e) => {
        this.$toast("复制成功");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        Message({
          message: "该浏览器不支持自动复制",
          type: "warning",
        });
        clipboard.destroy();
      });
    },
    // 拒绝订单
    refuseOrder() {
      refuse({ id: this.detail.orderDetail.id }).then((res) => {
        this.$toast.success("拒绝成功");
        this.close();
        this.$emit("refresh");
      });
    },
    // 确认订单
    confirmOrder() {
      confirm({ id: this.detail.orderDetail.id }).then((res) => {
        this.$toast.success("确认成功");
        this.close();
        this.$emit("refresh");
      });
    },
    // 入住
    liveInOrder() {
      liveIn({ id: this.detail.orderDetail.id }).then((res) => {
        this.$toast.success("入住成功");
        this.close();
        this.$emit("refresh");
      });
    },
    // 离店
    leaveOrder() {
      leave({ id: this.detail.orderDetail.id }).then((res) => {
        this.$toast.success("离店成功");
        this.close();
        this.$emit("refresh");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding-bottom: 30px;
  .room {
    font-size: 34px;
    font-weight: bold;
    padding-left: 34px;
    padding-top: 24px;
    margin-bottom: 10px;
  }
  .state {
    color: #00a8a8;
    padding-bottom: 24px;
    padding-left: 34px;
    font-size: 24px;
    border-bottom: 1px solid #e0e0e0;
  }
  .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
    margin-left: 36px;
    margin-top: 26px;
  }
  .copy {
    width: 76px;
    text-align: center;
    padding: 4px 0;
    height: 40px;
    border-radius: 22px;
    font-size: 24px;
    border: 1px solid #00a8a8;
    color: #00a8a8;
    margin-left: 14px;
  }
  .content {
    font-size: 30px;
    padding-left: 36px;
    margin-bottom: 22px;
    .left {
      width: 128px;
      color: #78828d;
      margin-right: 52px;
      text-align: left;
    }
  }
  .cancel {
    width: 338px;
    padding: 20px 0;
    text-align: center;
    border-radius: 46px;
    border: 2px solid #e0e0e0;
    font-size: 32px;
    font-weight: bold;
    margin-top: 65px;
    // margin-left: 36px;
  }
  .confirm {
    width: 338px;
    padding: 20px 0;
    text-align: center;
    border-radius: 46px;
    border: 2px solid #e0e0e0;
    font-size: 32px;
    font-weight: bold;
    margin-top: 65px;
    background-color: #00a8a8;
    color: #ffffff;
    margin-left: 32px;
  }
}
</style>