<template>
  <div>
    <van-tabs swipeable v-model="status" color="#00A8A8" sticky @change="changeTab">
      <van-tab v-for="item in tabs" :key="item.value" :name="item.value">
        <template #title>
          <div class="flex-start-center">
            {{item.label}}
            <p v-if="item.value == 2 && waitConfirmCount" class="flex-center-center count">{{waitConfirmCount}}</p>
          </div>
        </template>
        <div class="content">
          <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
            <van-list  v-model="loading" :finished="finished"  @load="getList">
              <order class="m-b-18" v-for="(item,index) in list" :order="item" :key="index" @tapOrder="showDetail(item)" />
            </van-list>
            <empty v-if="!loading && list.length == 0" title="无数据" />
            <div v-if="!loading && list.length == total" class="text-center">没有更多了</div>
          <!-- </van-pull-refresh> -->
        </div>
      </van-tab>
    </van-tabs>
    <detail ref="detail" @refresh="reset"/>
  </div>
</template>

<script>
import order from "./components/order.vue";
import detail from "./components/detail.vue";
import empty from "@/components/common/empty.vue";
import { orderList } from "@/api/order.js";
import { HOTEL_ORDER_STATUS } from '@/constants/order.js'
export default {
  components: { order, detail, empty },
  data() {
    return {
      tabs: [
        { label: "全部", value: 0 },
        { label: "待确认", value: 2 },
        { label: "待入住", value: 3 },
        { label: "已入住", value: 4 },
      ],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageIndex: 1,
      pageSize: 30,
      status: 0,
      total: 0,
      waitConfirmCount: 0,
      id: ''
    };
  },
  mounted() {
    const { id } = this.$route.query
    if (id) {
      this.showDetail({baseOrderId: id })
      this.id = id
    }
  },
  methods: {
    // 切换订单列表
    changeTab(value) {
      this.status = value;
      this.finished = false;
      this.loading = true;
      this.pageIndex = 1;
      this.list = [];
      this.getList();
    },
    reset(){
      this.finished = false;
      this.loading = true;
      this.pageIndex = 1;
      this.list = [];
      this.getList();
    },
    // 打开订单详情
    showDetail(order) {
      this.$refs.detail.open(order.baseOrderId);
    },
    // 获取订单列表
    async getList() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
        this.pageIndex = 1;
      }
      const res = await orderList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        status: this.status || "",
        baseOrderId: this.id
      });
      this.list = [...this.list, ...res.data];
      const waitConfirm = res.supplement.filter(item=>item.status === HOTEL_ORDER_STATUS.TO_BE_CONFIRMED)
      if (waitConfirm.length) this.waitConfirmCount = waitConfirm[0].count
      this.total = res.total;
      this.pageIndex++;
      this.loading = false;
      if (this.list.length >= this.total) {
        this.finished = true;
      }
    },
    // 下拉刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.count {
  width: 34px;
  height: 34px;
  font-size: 24px;
  background: rgba(255, 87, 51, 0.15);
  border-radius: 50%;
  margin-left: 6px;
  color: #ff5733;
}
.content {
  padding: 18px;
}
</style>